/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc All global CSS are defined here
 */

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;

} /* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important; 
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important; 
}
 /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */


body {
scrollbar-face-color: #f1f1f1 !important;
margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'manage_care_semibold';
  src: url('./font/proxima_nova_semibold.woff') format('woff');
}
@font-face {
	font-family: 'manage_care_bold';
  src: url('./font/proxima_nova_bold.woff') format('woff');
}

.MuiPickersDateRangePickerInput-root{
  width: 100%;
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
body {
	font-family: 'manage_care_semibold' !important;
}
code {
  font-family: 'manage_care_semibold' !important;
}
textarea:focus, input:focus{
  font-family: 'manage_care_semibold' !important;
}
input, select, textarea{
  font-family: 'manage_care_semibold' !important;
}

:fullscreen{
  z-index: 5;
}
